import * as React from 'react';
import ReportsIcon from '@material-ui/icons/Book';
import { Admin, Resource, Login } from 'react-admin';
import { restClient } from 'ra-data-feathers';
import feathers from "@feathersjs/client";
import { createBrowserHistory as createHistory } from 'history';
import createRealtimeSaga from "./realtime";

import config from './config';
import {
    DateField,
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    Layout,
} from 'react-admin';
import closeSidebar from './closesidebar';

const feathersClient = feathers().configure(feathers.rest(config.host).fetch(window.fetch.bind(window)));

const restClientOptions = {
    id: 'id', 
    usePatch: true 
};

const history = createHistory();
const dataProvider = restClient(feathersClient, restClientOptions);
const realTimeSaga = createRealtimeSaga(dataProvider);

const ReportsFilter = props => (
    <Filter {...props}>
        <TextInput label="Search Guilty IP" source="guiltyIP" alwaysOn />
    </Filter>
);

export const ReportsList = props => (
    <List {...props} bulkActionButtons={false} filters={<ReportsFilter />} exporter={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="guiltyIP" label="Guilty IP" />
            <TextField source="typeOfAttack" label="Type of Attack" />
            <TextField source="targetIP" label="Target IP" />
            <TextField source="targetDomain" label="Target Domain" />
            <TextField source="targetPort" label="Target Port" />
            <DateField source="createdAt" />
        </Datagrid>
    </List>
);

const None = () => {
    return (
        <div>
        </div>
    );
}


const MyLayout = (props) => {
    return(
    <Layout
        {...props}
        appBar={None}
        menu={None}
        notification={None}
    />
    )
};

const App = () => (
    <Admin
        title='IP Reporter'
        dataProvider={dataProvider}
        history={history}
        customSagas={[realTimeSaga]}
        appLayout={MyLayout}
        customSagas={[ closeSidebar ]}
    >
    <Resource
        name="reports"
        icon={ReportsIcon}
        list={ReportsList}
    />
    </Admin>
);
export default App;
